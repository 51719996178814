import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./parcelDetails.css";
import NavBar from "../../Components/navbar/navbar";
import {
  Input,
  Button,
  Row,
  Col,
  Modal,
  List,
  Radio,
  DatePicker,
  message
} from "antd";
import {
  callBusinessDirectly,
  callClient,
  callClientDirectly,
  getOneParcelByID,
  getSmsList,
  smsClient,
  updateParcelStatus,
} from "../../store/actions/parcelActions";
import { useNavigate, useParams } from "react-router-dom";
import MessageModal from "../home/MessageModal/MessageModal";
import { QrReader } from "react-qr-reader";
import { set } from "mongoose";

const ParcelDetails = () => {
  const navigate = useNavigate();
  const data = useSelector((state) => state);

  const { id } = useParams();
  const smsList = [
    {
      title: "Je suis en retard",
    },
    {
      title: "Je viens dans deux heures",
    },
    {
      title: "Votre colis est reporté pour demain",
    },
    {
      title: "Pas de colis pour aujourdhui",
    },
  ];
  const reasonsList = [

    {
      title: "Faux numéro",
    },
    {
      title: "Fausse adresse",
    },
    {
      title: "Fausse adresse",
    },
    {
      title: "Livraison annulée",
    },
    {
      title: "Autre raison",
    },
  ];

  const verificationList = [
    {
      title: "Fausse adresse",
    },
    {
      title: "Faux numéro",
    },
    {
      title: "Autre raison",
    }
  

  ];
  const verificationListWith3Tentative = [
    {
      title: "Fausse adresse",
    },
    {
      title: "Faux numéro",
    },
    {
      title: "Client non disponible",
    },
    {
      title: "Autre raison",
    }

  ];
  const verificationlistValid=data?.oneParcel?.parcel?.jobRequest?.nbTentative>=3?verificationListWith3Tentative:verificationList

  const reasonRelanceList = [
    {
      title: "Reporté par client",
    },
    {
      title: "Synchro",
    },
    {
      title: "client pas joignable",
    }
  ];
  const dispatch = useDispatch();
  const [relanceReason, setRelanceReason] = useState('');

  const [reportedToReasonValue, setreportedToReasonValue] = useState("");
  const [verificationReasonValue, setVerificationReasonValue] = useState("");
  const [notes, setNotes] = useState('');
  const [smsText, setSmsText] = useState("");
  const [cameraOpen, setCameraOpen] = useState(false);
  const [pinCode, setPinCode] = useState('0000');
  const [pinCodeError, setPinCodeError] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);
  const [visibleSMSModal, setVisibleSMSModal] = useState(false);
  const [visibleVerifactionModal, setVisibleVerificationModal] = useState(false)
  const [visibleReasonModal, setVisibleReasonModal] = useState(false);
  const [visibleDeliveryModal, setVisibleDeliveryModal] = useState(false);
  const [visibleRelanceModal, setVisibleRelanceModal] = useState(false);
  const [visibleEnCoursModal, setVisibleEnCoursModal] = useState(false);
  const [verificationOtherReason, setVerificationOtherReason] = useState('');
  const [messageType, setMessageType] = useState('sms');
  const [parcelDetails, setParcelDetails] = useState(null);
  const [otherReturnedRaison, setOtherReturnedRaison] = useState('');
  const [driverComment,setDriverComment]=useState('')

  const handleOKSMSModal = (phone) => {
    if (smsText) {
      dispatch(smsClient(smsText, phone));
      setVisibleSMSModal(false);
    }
  };
  const handleCancelSMSModal = () => {
    setVisibleSMSModal(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const sendDriverSmsToClient = () => {
    setMessageType('sms');
    setIsModalVisible(true);
  };
const handelCommentChange=(e)=>{
  setDriverComment(e.target.value)
}
  const sendWhatsAppMessage = () => {
    setMessageType('whatsapp');
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const handleSelectMessage = (message) => {

    const { deliveredToPhone } = data.oneParcel.parcel.jobRequest;
    if (!deliveredToPhone) return;

    if (messageType === 'sms') {
      window.location.href = `sms:${deliveredToPhone}?body=${encodeURIComponent(message.text)}`;
    } else if (messageType === 'whatsapp') {
      const url = `https://api.whatsapp.com/send?phone=216${deliveredToPhone}&text=${encodeURIComponent(message.text)}&app_absent=0`;
      const newTab = window.open(url, '_blank');
      const isIphone = /iPhone/i.test(navigator.userAgent);
      if (isIphone) {
        newTab.close();
      }
    }
  };



  const handelRelanceReasonChange = (e) => {
    setRelanceReason(e.target.value)
  }

  useEffect(() => {
    if (!localStorage.getItem("parcelNotes")) {
      localStorage.setItem("parcelNotes", JSON.stringify({}));
    }
    const savedNotes = JSON.parse(localStorage.getItem("parcelNotes"));
    if (savedNotes && data.oneParcel?.parcel?.jobRequest?._id) {
      setNotes(savedNotes[data.oneParcel.parcel.jobRequest._id] || '');
    }
  }, [data.oneParcel?.parcel?.jobRequest?._id]);

  const handleNotesChange = (newNotes) => {
    if (!localStorage.getItem("parcelNotes")) {
      localStorage.setItem("parcelNotes", JSON.stringify({}));
    }
    const savedNotes = JSON.parse(localStorage.getItem("parcelNotes"));
    savedNotes[data.oneParcel.parcel.jobRequest._id] = newNotes;
    localStorage.setItem("parcelNotes", JSON.stringify(savedNotes));
    setNotes(newNotes);
  };

  const debouncedHandleNotesChange = useCallback(debounce(handleNotesChange, 500), [data.oneParcel?.parcel?.jobRequest?._id]);

  const showReasonModal = (p) => {
    setParcelDetails(p);
    setVisibleReasonModal(true);
  };

  const showDeliveryModal = (p) => {
    if (isRefreshLoading) return;

    setIsRefreshLoading(true);
    try {
      dispatch(getOneParcelByID(id));
      setParcelDetails(p);
      setVisibleDeliveryModal(true);
    } catch (error) {
      message.warning('Veuillez actualiser la page');
    } finally {
      setIsRefreshLoading(false);
    }
  };
  const showRelanceModal = (p) => {
    if (isRefreshLoading) return;

    setIsRefreshLoading(true);
    try {
      dispatch(getOneParcelByID(id));
      setParcelDetails(p);
      setVisibleRelanceModal(true);
    } catch (error) {
      message.warning('Veuillez actualiser la page');
    } finally {
      setIsRefreshLoading(false);
    }
  };
  useEffect(() => {
    if (verificationReasonValue === "Autre raison") {
      setDriverComment('')
    }
  }, [verificationReasonValue]);
  const showVerificationModal = (p) => {
    if (isRefreshLoading) return;

    setIsRefreshLoading(true);
    try {
      dispatch(getOneParcelByID(id));
      setParcelDetails(p);
      setVisibleVerificationModal(true);
    } catch (error) {
      message.warning('Veuillez actualiser la page');
    } finally {
      setIsRefreshLoading(false);
    }
  };
  const handlePinCodeChange = (e) => {
    setPinCode(e.target.value);
    if (e.target.value) {
      setPinCodeError(false);
    }
  };

  const handleOKDeliveryModal = async () => {
    if (!pinCode) {
      setPinCodeError(true);
      message.error('Le code pin est requis');
      return;
    }

    let deliveryGeolocationValue = null;
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      deliveryGeolocationValue = {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      };
    } catch (error) {
      console.error('Accès à la géolocalisation refusé ou indisponible');
    }

    dispatch(updateParcelStatus(parcelDetails._id, 6, null, null, id, pinCode, deliveryGeolocationValue));
    setPinCode(null);
    setVisibleDeliveryModal(false);
  };

  const handelRelance = async () => {

    if (!relanceReason) {
      message.error('Veuillez choisir une raison de relance.');}
      else{

        
        let deliveryGeolocationValue = null;
        try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      deliveryGeolocationValue = {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      };
    } catch (error) {
      console.error('Accès à la géolocalisation refusé ou indisponible');
    }

    dispatch(updateParcelStatus(parcelDetails._id, 29, relanceReason, null, null, pinCode, deliveryGeolocationValue));
    setPinCode(null);
    setVisibleRelanceModal(false);
  }
  };

  const handleCancelDeliveryModal = () => {
    setVisibleDeliveryModal(false);
  };
  const handelCloseRelanceModal = () => {
    setVisibleRelanceModal(false);
  };
  const showEnCoursModal = (p) => {
    setParcelDetails(p);
    setVisibleEnCoursModal(true);
  };
  const handleOKEnCoursModal = () => {
    dispatch(updateParcelStatus(parcelDetails?._id, 10, null, null, id));
    setVisibleEnCoursModal(false);
  };
  const handleCancelEnCoursModal = () => {
    setVisibleEnCoursModal(false);
  };
  const handelOtherReasonChange = (e) => {  
    setVerificationOtherReason(e.target.value);
  }
  const handleOKReasonModal = () => {
    if (reportedToReasonValue === "") {
      return message.error('Veuillez choisir une raison de retour.');
    }
    else{

      if (reportedToReasonValue === "Autre raison") {
        dispatch(updateParcelStatus(
      parcelDetails._id,
      16,
      otherReturnedRaison,
    ))
    setVisibleReasonModal(false);
    setOtherReturnedRaison("");
    setreportedToReasonValue("");} else {
      dispatch(updateParcelStatus(
        parcelDetails._id,
        16,
        reportedToReasonValue,
      ))
      setVisibleReasonModal(false);
      setreportedToReasonValue("");
      
    };
  }
  };
  const handleCancelReasonModal = () => {
    setVisibleReasonModal(false);
  };
  const handelCancelVerificationModal = () => {
    setVisibleVerificationModal(false);
  }
 
  const handleReasonChange = (e) => {
    setreportedToReasonValue(e.target.value);
  };
  const retournedReasonList = [
    {
      title: "Retour définitif",
    },
    {
      title: "Autre raison",
    }
  ];
  const handelOtherReturnedRaison = (e) => {
    setOtherReturnedRaison(e.target.value);
  }

  // function onChangeReason(e) {
  //   if (e.target.value === "Autre raison") {
  //     setOtherReasonValue("");
  //     setOtherReason(true);
  //     setReportedToReason(false);

  //   } else if (e.target.value === "Livraison reportée") {
  //     setreportedToReasonValue("");
  //     setReportedToReason(true);
  //     setOtherReason(false);

  //     setOtherReasonValue(e.target.value);
  //   } else {
  //     setOtherReason(false);
  //     setReportedToReason(false);
  //     setOtherReasonValue(e.target.value);
  //   }
  // }
  const handelVerifactionReasonChange = (e) => {
    setVerificationReasonValue(e.target.value);
  }

  function onChangeSMS(e) {
    setSmsText(e.target.value);
  }

  const handleClick = (index) => {
    console.log(smsList[index]);
  };
  const handelVerifactionUpdate = () => {
    if (verificationReasonValue === "" && verificationOtherReason === "") 
      return message.error('Veuillez choisir une raison de vérification.');
    if (verificationReasonValue === "Autre raison") {
      setDriverComment('')
      if (verificationOtherReason === "")
        return message.error('Veuillez saisir une autre raison.');
    }
    if (verificationOtherReason){
      dispatch(updateParcelStatus(
        parcelDetails._id,
        28,
        verificationOtherReason,
     
      ));
      setVisibleVerificationModal(false);
      setVerificationReasonValue("");
    }
   else {
      dispatch(updateParcelStatus(
        parcelDetails._id,
        28,
        verificationReasonValue,
      
      ));
      setVisibleVerificationModal(false);
      setVerificationReasonValue("");
    }
  };
  const convertNumber = (n) => {
    if (n) {
      let c = n.split("").join("%2A");
      return c;
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  useEffect(() => {
    dispatch(getOneParcelByID(id));
    dispatch(getSmsList());
  }, []);

  const openCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          setCameraOpen(!cameraOpen);
        })
        .catch((err) => {
          message.error("Tu as refusé la demande de camera.");
        });
    } else {
      message.error("La caméra n'est pas disponible sur ce navigateur.");
    }
  };

  const handleScan = (data) => {
    if (data) {
      setCameraOpen(false);
      navigate(`/parcel/${data.text}`)
    }
  };

  const handleError = (err) => {
    console.error(err);
    setCameraOpen(false);
  };


  return (
    <>
      <NavBar openCamera={openCamera} />
      {cameraOpen && (
        <div className="camera-container">
          <QrReader
            delay={300}
            onError={handleError}
            onResult={handleScan}
            style={{ width: '100%' }}
            constraints={{ facingMode: 'environment' }}
          />
        </div>
      )}
      {data.oneParcel.loading ? (
        <div className="loading-text">Chargement ...</div>
      ) : (
        <div>

          <div className="">
            <Row className="parcel-preview">
              <Col span={6} className="parcel-nid parcel-details-header">
                {data.oneParcel?.parcel?.jobRequest?.nid}
              </Col>
              <Col span={18} className="parcel-nid parcel-details-header">
                {data.oneParcel?.parcel?.jobRequest?.deliveredToName}
              </Col>
              {data.oneParcel.parcel?.jobRequest?.isCancelled && (
                <Col span={24} className="parcel-exchange parcel-details-header">
                  Annulé
                </Col>
              )}
              {data.oneParcel.parcel?.jobRequest?.isExchange && (
                <Col span={24} className="parcel-exchange parcel-details-header">
                  Échange
                </Col>
              )}
              <Col span={24} className="parcel-from-to parcel-details-header">
                <Row>
                  <Col span={20} className="parcel-details-item column-border">
                    {data.oneParcel.parcel?.jobRequest?.business?.nomCommercial}{" "}
                  </Col>
                  <Col span={2} className="parcel-details-item call-ml" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="phone" onClick={() => dispatch(callBusinessDirectly(data.oneParcel?.parcel.jobRequest.business.tel))}>
                      <path fill="#2d51da" d="M23.45 20.93a3 3 0 0 0-4.25 0l-1.45 1.45a26.14 26.14 0 0 1-4.51-3.62 26.62 26.62 0 0 1-3.62-4.51l1.45-1.45a3 3 0 0 0 0-4.25L8.24 5.73a2.93 2.93 0 0 0-2.11-.88h0A3 3 0 0 0 4 5.73L2.64 7.08A6.37 6.37 0 0 0 1 12.33c.32 3.75 2.78 8.26 6.57 12.06S15.92 30.64 19.67 31a7.87 7.87 0 0 0 .84 0 6.07 6.07 0 0 0 4.41-1.64L26.27 28a3 3 0 0 0 .88-2.13 2.93 2.93 0 0 0-.88-2.11zm1.41 5.66L23.5 27.94a4.57 4.57 0 0 1-3.66 1c-3.25-.28-7.39-2.58-10.81-6S3.31 15.41 3 12.16a4.53 4.53 0 0 1 1-3.66L5.41 7.14a1 1 0 0 1 .71-.29h0a1 1 0 0 1 .71.29L9.66 10a1 1 0 0 1 0 1.41l-2 2a1 1 0 0 0-.16 1.21 27.49 27.49 0 0 0 4.33 5.58 27.49 27.49 0 0 0 5.58 4.33 1 1 0 0 0 1.21-.16l2-2a1 1 0 0 1 1.41 0l2.83 2.83h0a1 1 0 0 1 .29.71A1 1 0 0 1 24.86 26.59zM26.6 5.39A14.92 14.92 0 0 0 16 1a1 1 0 1 0 0 2A13 13 0 0 1 29 16.11a1 1 0 0 0 1 1h0a1 1 0 0 0 1-1A14.91 14.91 0 0 0 26.6 5.39z"></path>
                      <path fill="#2d51da" d="M20.91,11.11a6,6,0,0,1,1.77,4.31,1,1,0,0,0,1,1h0a1,1,0,0,0,1-1,8,8,0,0,0-8-8.1,1,1,0,1,0,0,2A6,6,0,0,1,20.91,11.11Z"></path>
                    </svg>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="">
              <Col span={18} className="">
                <div className="parcel-details-part" style={{ fontSize: "1.25em" }}>
                  {data.oneParcel.parcel?.jobRequest?.descProduit ? (
                    <div>
                      {data.oneParcel.parcel?.jobRequest?.descProduit}
                    </div>
                  ) : null}
                  {data.oneParcel.parcel?.jobRequest?.addInfo ? (
                    <div>
                      {data.oneParcel.parcel?.jobRequest?.addInfo}
                    </div>
                  ) : null}
                  <Row>
                    <Col span={22} style={{ fontWeight: 600 }}>
                      {" "}
                      {data.oneParcel.parcel?.jobRequest?.dropOffAddress} -{" "}
                      {data.oneParcel.parcel?.jobRequest?.city}
                    </Col>{" "}
                  </Row>
                </div>
              </Col>
              <Col span={6} className="" >
                <div className="parcel-details-zone" style={{ fontSize: "1.25em" }}>
                  {data.oneParcel.parcel?.zone}
                </div>
              </Col>
            </Row>
            <Row className="">
              <Col span={18} className="Tentative"> Nombre de Tentative : {data.oneParcel.parcel?.jobRequest?.nbTentative}</Col>
              </Row>
            <Row className="">
              <Col span={18} className="">
              </Col>
              <Col span={6} className="" >
                <div className="parcel-details-zone" style={{ fontSize: "1.25em" }}>
                  {data.oneParcel.parcel?.jobRequest?.amount} DT

                </div>
              </Col>
            </Row>

            <Row className="action-button-container" >
              <Col span={3} className="parcel-details-menu-item" onClick={() => dispatch(callClient(data.oneParcel?.parcel.jobRequest._id, 1, `tel:%23%2370223008%2C${convertNumber(data.oneParcel?.parcel.jobRequest?.nid)}%23`))}>
                <Button
                  className="action-button py-4"
                >
                  Écoute
                </Button>
              </Col>

              {
                !data.oneParcel?.parcel?.jobRequest.driver.recordCalls &&
                <Col span={3} className="parcel-details-menu-item" onClick={() => dispatch(callClientDirectly(data.oneParcel?.parcel.jobRequest._id, data.oneParcel?.parcel.jobRequest.deliveredToPhone))}>
                  <Button
                    className="action-button py-4"
                  >
                    Appel
                  </Button>
                </Col>
              }

              <Col span={4} className="parcel-details-menu-item" onClick={sendDriverSmsToClient}>
                <Button
                  className="action-button py-4"
                >
                  SMS
                </Button>
              </Col>

              <Col span={4} className="parcel-details-menu-item" onClick={sendWhatsAppMessage} >
                <Button
                  className="action-button py-4"
                >
                  Whatsapp
                </Button>
              </Col>
            </Row>

            {(data.oneParcel.parcel?.jobRequest.comments.length
              || data.oneParcel.parcel.amountChanged || data.oneParcel.parcel.addressChanged
              || data.oneParcel.parcel.phoneChanged || data.oneParcel.parcel.exchangeChanged
              || data.oneParcel.parcel.delayChanged || data.oneParcel.parcel.cancelChanged) && (
                <Row
                  gutter={[16, 16]}
                  className="parcel-details-part display-flex"
                  style={{
                    fontWeight: 600,
                    fontSize: "1.2em",
                    textAlign: "center",
                  }}
                >
                  <Col span={24} style={{ textAlign: 'left' }}>
                    <h3 className="notifications-header">Notifications:</h3>
                  </Col>

                  {data.oneParcel.parcel?.jobRequest.comments.map((comment, index) => (
                    <Col key={index} span={24} style={{ textAlign: 'left' }}>
                      <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>{comment.text}</div>
                        <div style={{ fontSize: '1em', color: 'gray', textAlign: 'right' }}>
                          {new Date(comment.date).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })}                    </div>
                      </div>
                    </Col>
                  ))}

                  {data.oneParcel.parcel.cancelChanged && (
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <div style={{ fontSize: '0.8em', marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>Il a été annulé</div>
                        <div style={{ color: 'gray', textAlign: 'right' }}>
                          {data.oneParcel.parcel.cancelChangeDate && (
                            new Date(data.oneParcel.parcel.cancelChangeDate).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })
                          )}
                        </div>
                      </div>
                    </Col>
                  )}

                  {data.oneParcel.parcel.amountChanged && (
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <div style={{ fontSize: '0.8em', marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>Changement de prix</div>
                        <div style={{ color: 'gray', textAlign: 'right' }}>
                          {data.oneParcel.parcel.amountChangeDate && (
                            new Date(data.oneParcel.parcel.amountChangeDate).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })
                          )}
                        </div>
                      </div>
                    </Col>
                  )}

                  {data.oneParcel.parcel.addressChanged && (
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <div style={{ fontSize: '0.8em', marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>Changement d'addresse</div>
                        <div style={{ color: 'gray', textAlign: 'right' }}>
                          {data.oneParcel.parcel.addressChangeDate && (
                            new Date(data.oneParcel.parcel.addressChangeDate).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })
                          )}
                        </div>
                      </div>
                    </Col>
                  )}

                  {data.oneParcel.parcel.phoneChanged && (
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <div style={{ fontSize: '0.8em', marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>Changement de téléphone</div>
                        <div style={{ color: 'gray', textAlign: 'right' }}>
                          {data.oneParcel.parcel.phoneChangeDate && (
                            new Date(data.oneParcel.parcel.phoneChangeDate).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })
                          )}
                        </div>
                      </div>
                    </Col>
                  )}

                  {data.oneParcel.parcel.exchangeChanged && (
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <div style={{ fontSize: '0.8em', marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>Changement d'état d'échange</div>
                        <div style={{ color: 'gray', textAlign: 'right' }}>
                          {data.oneParcel.parcel.exchangeChangeDate && (
                            new Date(data.oneParcel.parcel.exchangeChangeDate).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })
                          )}
                        </div>
                      </div>
                    </Col>
                  )}

                  {data.oneParcel.parcel.delayChanged && (
                    <Col span={24} style={{ textAlign: 'left' }}>
                      <div style={{ fontSize: '0.8em', marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
                        <div>il y aura un retard</div>
                        <div style={{ color: 'gray', textAlign: 'right' }}>
                          {data.oneParcel.parcel.delayChangeDate && (
                            new Date(data.oneParcel.parcel.delayChangeDate).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false })
                          )}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              )}

            <Row
              gutter={[16, 16]}
              className="parcel-details-part display-flex"
              style={{
                fontWeight: 600,
                fontSize: "1.2em",
                textAlign: "center",
              }}
            >
              <Col span={24} style={{ textAlign: 'left' }}>
                <h3 className="notifications-header">Notes:</h3>
                <textarea
                  value={notes}
                  onChange={(e) => {
                    setNotes(e.target.value)
                    debouncedHandleNotesChange(e.target.value)
                  }}
                  style={{ width: '100%', height: '150px', fontSize: '1em', padding: '1rem' }}
                />

              </Col>
            </Row>

            <div className="parcel-details-part">
              <Row>
                <Col span={5} className="display-flex">
                  {" "}
                  <Button
                    disabled={data.oneParcel.parcel?.jobRequest?.status === 6 || isRefreshLoading}
                    hidden={
                      ![10, 6].includes(
                        data.oneParcel.parcel?.jobRequest?.status
                      )
                    }
                    style={{
                      width: "100%",
                      color: "green",
                      border: "1px solid green",
                    }}
                    onClick={() =>
                      showDeliveryModal(data.oneParcel.parcel?.jobRequest)
                    }
                  >
                    Livré
                  </Button>
                </Col>
                <Col span={1} />
                <Col span={5} className="display-flex">
                  {" "}
                  <Button
                    disabled={data.oneParcel.parcel?.jobRequest?.status === 6 || isRefreshLoading}
                    className="relance-button"

                    onClick={() =>
                      showRelanceModal(data.oneParcel.parcel?.jobRequest)
                    }
                  >
                    Relance
                  </Button>
                </Col>
                
                <Col span={1} />
                <Col span={6}>
                  {" "}
                  <Button
                    disabled={data.oneParcel.parcel?.jobRequest?.status === 15}
                    hidden={
                      ![10, 15].includes(
                        data.oneParcel.parcel?.jobRequest?.status
                      )
                    }
                    style={{
                      width: "100%",
                      color: "orange",
                      border: "1px solid orange",
                    }}
                    onClick={() =>
                      showVerificationModal(data.oneParcel.parcel?.jobRequest)
                    }
                  >
                    Verification
                  </Button>
                </Col>
                <Col span={1} />
                <Col span={5}>
                  {" "}
                  <Button
                    disabled={data.oneParcel.parcel?.jobRequest?.status === 15}
                    hidden={
                      ![10, 15].includes(
                        data.oneParcel.parcel?.jobRequest?.status
                      )
                    }
                    style={{
                      width: "100%",
                      color: "red",
                      border: "1px solid red",
                    }}
                    onClick={() =>
                      showReasonModal(data.oneParcel.parcel?.jobRequest)
                    }
                  >
                    Retour
                  </Button>
                </Col>

              </Row>
            </div>
            <Button
              hidden={![15,16,28,29].includes(data.oneParcel.parcel?.jobRequest?.status)}
              style={{
                width: "100%",
                background: "#45bbc2",
                color: "white",
                fontWeight: "600",
              }}
              onClick={() =>
                showEnCoursModal(data.oneParcel.parcel?.jobRequest)
              }
            >
              Mettre en cours
            </Button>
          </div>
          <Modal
            className="sms-modal"
            title="Envoyer SMS pour le client"
            visible={visibleSMSModal}
            onOk={() =>
              handleOKSMSModal(
                data.oneParcel.parcel?.jobRequest?.deliveredToPhone
              )
            }
            onCancel={handleCancelSMSModal}
          >
            <Radio.Group style={{ width: "100%" }} onChange={onChangeSMS}>
              <List
                itemLayout="horizontal"
                dataSource={data.oneParcel?.smsList}
                renderItem={(item, index) => (
                  <List.Item onClick={() => handleClick(index)}>
                    <List.Item.Meta
                      title={<Radio value={item.text}>{item.text}</Radio>}
                    />
                  </List.Item>
                )}
              />{" "}
            </Radio.Group>
          </Modal>
          <Modal
            className="reason-modal"
            title="Préciser le raison de verification"
            visible={visibleVerifactionModal}
            onOk={handelVerifactionUpdate}
            onCancel={handelCancelVerificationModal}
          >
             <Radio.Group style={{ width: "100%" }} onChange={handelVerifactionReasonChange}>
          <List
            itemLayout="horizontal"
            dataSource={verificationListWith3Tentative}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Radio
                      value={item.title}
                      disabled={item.title === "Client non disponible" &&data?.oneParcel?.parcel?.jobRequest?.nbTentative <= 3}
                    >
                      {item.title}
                    </Radio>
                  }
                />
              </List.Item>
            )}
          />
          {
            verificationReasonValue === "Autre raison" && (
              <Input
                placeholder="Autre raison"
                onChange={handelOtherReasonChange}
              />
            )
          }
        </Radio.Group>
        {
          verificationReasonValue !== "Autre raison" && (
            <Input
              placeholder="votre commentaire"
              onChange={handelCommentChange}
            />
          )
        }
          </Modal>
          <Modal
            className="reason-modal"
            title="Préciser le raison de retour"
            visible={visibleReasonModal}
            onOk={handleOKReasonModal}
            onCancel={handleCancelReasonModal}
          >


         <Radio.Group style={{ width: "100%" }} onChange={handleReasonChange}>

          <List
            itemLayout="horizontal"
            dataSource={retournedReasonList}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<Radio value={item.title}  disabled={item.title === "Autre raison" && data?.oneParcel?.parcel?.jobRequest?.nbTentative <= 3}>{item.title}</Radio>}
                />
              </List.Item>
            )}
          />
          {
            reportedToReasonValue === "Autre raison" && <Input
              placeholder="raison de retour"
              onChange={handelOtherReturnedRaison}
            />
          }

          

        </Radio.Group>

        {
            reportedToReasonValue === "Retour définitif" && <span style={{color:"red"}}>Retour final c’est livraison annulé par client</span>
          }
   
   {reportedToReasonValue !== 'Autre raison' &&<Input
                    placeholder="votre commentaire"
                    onChange={handelCommentChange}
                  />}
          </Modal>
          <Modal
            className="reason-modal"
            title="Préciser le raison de retour"
            visible={visibleRelanceModal}
            onOk={handelRelance}
            onCancel={handelCloseRelanceModal}
          >

            <Radio.Group style={{ width: "100%" }} onChange={handelRelanceReasonChange}>
              <List
                itemLayout="horizontal"
                dataSource={reasonRelanceList}
                renderItem={(item, index) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<Radio value={item.title}>{item.title}</Radio>}
                    />
                  </List.Item>
                )}
              />

            </Radio.Group>
            <Input
                    placeholder="votre commentaire"
                    onChange={handelCommentChange}
                  />
          </Modal>
          <Modal
            className="reason-modal"
            visible={visibleDeliveryModal}
            onOk={handleOKDeliveryModal}
            onCancel={handleCancelDeliveryModal}
          >
            Vous êtes entrain de confirmer la livraison de coli{" "}
            {parcelDetails?.nid}, prix: <strong>{data.oneParcel.parcel?.jobRequest?.amount} DT</strong>.
            <Input
              placeholder="Enter Code Pin"
              value={pinCode}
              onChange={handlePinCodeChange}
              status={pinCodeError ? 'error' : ''}
              style={{ marginTop: '1rem' }}
            />
            {pinCodeError && <p style={{ color: 'red' }}>Le code pin est obligatoire</p>}
            <Input
                    placeholder="votre commentaire"
                    onChange={handelCommentChange}
                  />
          </Modal>
          <Modal
            className="reason-modal"
            visible={visibleEnCoursModal}
            onOk={handleOKEnCoursModal}
            onCancel={handleCancelEnCoursModal}
          >
            Vous êtes entrain denavigate re-mettre le coli {parcelDetails?.nid} en
            cours.
          </Modal>

          <MessageModal
            visible={isModalVisible}
            onClose={handleModalClose}
            onSelectMessage={handleSelectMessage}
            parcelId={data.oneParcel?.parcel?.jobRequest._id}
          />
        </div>
      )}
    </>
  );
};

export default ParcelDetails;
